import * as React from 'react';
import './GroupDetails.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import GroupService from '../../../../services/GroupService';
import { IGroupMemberInfo } from '../../../../interfaces/IGroupMemberInfo';
import GroupResultBar from './groupResultBar/GroupResultBar';

export interface IProps {
  id: string;
  appStore?: AppStore;
}

export interface IState {
  typesAndScores: { type: number, score: number }[];
  members?: IGroupMemberInfo[];
}


@inject('appStore')
@observer
export default class GroupDetails extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      typesAndScores: [
        { type: 1, score: 0 },
        { type: 2, score: 0 },
        { type: 3, score: 0 },
        { type: 4, score: 0 },
        { type: 5, score: 0 },
        { type: 6, score: 0 },
        { type: 7, score: 0 },
        { type: 8, score: 0 },
        { type: 9, score: 0 },
      ]
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.id && props.id != this.props.id) {
      GroupService.getGroupMemberInfoForGroup(props.id).then((groupMembersInfo: IGroupMemberInfo[]) => {
        console.log(groupMembersInfo);
        this.setState({ members: groupMembersInfo })
        const typesAndScores = [
          { type: 1, score: 5 },
          { type: 2, score: 31 },
          { type: 3, score: 13 },
          { type: 4, score: 8 },
          { type: 5, score: 0 },
          { type: 6, score: 24 },
          { type: 7, score: 18 },
          { type: 8, score: 4 },
          { type: 9, score: 19 },
        ];
        // groupMembersInfo.forEach((groupMemberInfo: IGroupMemberInfo) => {
        //   typesAndScores[groupMemberInfo.type.toString()]++;
        // });
        typesAndScores.sort((a, b) => b.score - a.score);
        this.setState({ typesAndScores });
        console.log(typesAndScores);
      });

    }
  }

  private showResult(): JSX.Element[] {
    return this.state.typesAndScores.map((typeAndScore, index) => {
      return (
        <GroupResultBar
          key={`resultBar_${typeAndScore.type}`}
          type={typeAndScore.type}
          points={typeAndScore.score}
          scoreForWinner={this.state.typesAndScores[0].score}
          totalParticipants={this.state.members.length}
          winner={index === 0}
          backgroundColor={"#064859"}
          barColor={"#064859"}
          textColor={"#ffffff"}
          iconBackgroundColor={"#064859"}
          iconTextColor={"#ffffff"}
          onClick={() => {
          }}
        />
      );
    });
  }

  render() {
    return (
      <div>
        <div
          className="groupDetailsPanelWrapper"
          style={{
            visibility: this.props.appStore.groupToView != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.groupToView = undefined;
          }}
        />
        <div
          className="groupDetailsPanel"
          style={{
            right: this.props.appStore.groupToView != undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.groupToView = undefined}
          />
          <h3>Gruppe detailer</h3>
          <div className='result'>
            {this.state.members && this.showResult()}
          </div>
        </div>
      </div>
    );
  }
}